import { InjectionToken } from '@angular/core';

export const UrlMap = {
  patient_active_medication: '/patients/{{patientName}}/medication/therapies/active/validated',
  navigate_patient_file: '/patients/{{patientName}}',
} as const;

export type LinkName = keyof typeof UrlMap;

export const UrlMapToken = new InjectionToken<Record<string, string>>('UrlMap');

/**
 * Offers possibility to overwrite default url mapping
 */
export function provideUrlMap(urlMap: Partial<Record<LinkName, string>>) {
  return {
    provide: UrlMapToken,
    useValue: urlMap,
  };
}
