import { Validators } from '@angular/forms';
import { Facet as InsurabilityFacet } from '@nexuzhealth/shared/finance/data-access-insurability';
import { ComponentSettings } from '@nexuzhealth/shared/settings/data-access-settings';

export const componentsConfig: ComponentSettings = {
  components: {
    'patient-page': {
      loadOpenContact: false,
      smedVisible: false,
      path: 'pm',
    },
    'patient-records-page': {
      displayedFields: [
        'given',
        'family',
        'nick',
        'assignedIds',
        'birthDate',
        'deathDate',
        'administrativeGender',
        'maritalStatus',
        'nationalities',
        'communicationLanguages',
        'writtenLanguage',
        'profession',
      ],
      validators: [
        ['nationalities', Validators.required],
        ['administrativeGender', Validators.required],
        ['city', Validators.required],
        ['postalCode', Validators.required],
        ['country', Validators.required],
      ],
      validatePersonDataVisible: false,
      'create-contact-button-visible': true,
    },
    'eid-modal-component': {
      routeOnSuccess: ['/pm', null, 'administrative-dossier'],
    },
    patient: {
      'search-full': {
        alert: {
          body: '_patient.search-alert-body-hosp',
        },
        displayedColumns: ['patient', 'inss', 'phoneNumber', 'birthDay', 'home-address', 'actions'],
        simpleSearchEnabled: false,
        advancedSearchEnabled: true,
        onlyGivenAllowed: false,
      },
      link: '/pm',
    },
    'patient-search-result-table-INSS': {
      displayedColumns: [
        'givenNames',
        'lastName',
        'ssin',
        'genderName',
        'birthDate',
        'birthPlace',
        'residentialAddress',
      ],
      createAddress: true,
    },
    'patient-search-result-table-PHONETIC': {
      displayedColumns: [
        'givenNames',
        'lastName',
        'ssin',
        'genderName',
        'birthDate',
        'birthPlace',
        'residentialAddress',
      ],
      createAddress: true,
    },
    'administrative-dossier': {
      insurability: {
        facets: [
          InsurabilityFacet.insurability,
          InsurabilityFacet.hospital,
          InsurabilityFacet.carePath,
          InsurabilityFacet.referencePharmacy,
          InsurabilityFacet.palliative,
          InsurabilityFacet.gmf,
          InsurabilityFacet.generalsituation,
        ],
        titleType: 'long',
        paymentVisible: true,
        thirdPayerVisible: true,
      },
      ehealth: {
        informedConsents: true,
        therapeuticRelations: false,
      },
      hideBisButton: true,
    },
  },
  //Shared routes defined in documents-routing.module.ts
  routeconfig: {
    documents: {
      routes: ['internal'],
    },
  },
};
