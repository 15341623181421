import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthQuery, AuthService } from '@nexuzhealth/shared/authentication/data-access-auth';
import { AccessRole, RoleExpr } from '@nexuzhealth/shared/domain';
import { FeatureFlagQuery, FeatureFlagService } from '@nexuzhealth/shared/settings/feature-flags/data-access';
import { map } from 'rxjs/operators';
import { NavbarItem } from '@nexuzhealth/shared/toolkit/feature-shell';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './app-shell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppShellComponent implements OnInit {
  userContext = this.authQuery.getUserContext();

  navitems$: Observable<NavbarItem[]> = this.featureFlagQuery.selectFeatureFlagEnabled('featureflags/nxh-mail').pipe(
    map((nxhMailEnabled) => {
      const navitems: Array<NavbarItem & { roleExpr?: RoleExpr }> = [
        {
          label: 'hosp:patients',
          url: '/pm',
          roleExpr: { or: [AccessRole.clerk, AccessRole.clinical] },
        },
        {
          label: '_messages.navbar.title',
          url: '/messages',
          roleExpr: nxhMailEnabled ? undefined : AccessRole.clinical,
        },
        {
          label: '_resourceplanning.navbar.title',
          url: '/resourceplanning',
        },
        {
          label: '_medication._pharmacy.navbar.title',
          url: '/pharmacy',
          roleExpr: AccessRole.clinical,
        },
        {
          label: '_settings.navbar.title',
          url: '/settings',
        },
        {
          label: 'worklists',
          url: '/worklists',
          featureFlag: 'featureflags/worklist',
        },
      ];

      return navitems.filter((item) => this.authService.isAuthorized(item.roleExpr));
    })
  );

  AccessRole = AccessRole;

  ngOnInit() {
    this.featureFlagService.loadMany('featureflags/multimedia_save-as-draft').subscribe();
  }

  constructor(
    private authService: AuthService,
    private authQuery: AuthQuery,
    private featureFlagQuery: FeatureFlagQuery,
    private featureFlagService: FeatureFlagService
  ) {}
}
