import { Routes } from '@angular/router';
import { isAuthorizedGuard, isAuthorizedGuardFactory } from '@nexuzhealth/shared/authentication/feature-authorization';
import { AuthGuard } from '@nexuzhealth/shared/authentication/data-access-auth';
import { ReferenceResolveService } from '@nexuzhealth/shared/reference-data/data-access';
import { PageNotFoundComponent } from '@nexuzhealth/shared/ui-toolkit/not-found';
import { AuthenticatedThemeResolver } from '@nexuzhealth/shared/toolkit/feature-theming';
import {
  FeatureFlagQuery,
  FeatureFlagResolver,
  FeatureFlagService,
} from '@nexuzhealth/shared/settings/feature-flags/data-access';
import { FatalErrorPageComponent } from '@nexuzhealth/shared/toolkit/feature-shell';
import { AccessRole } from '@nexuzhealth/shared/domain';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AppShellComponent } from './app-shell/app-shell.component';

export const routes: Routes = [
  {
    path: 'fatal-error',
    component: FatalErrorPageComponent,
  },
  {
    path: '',
    component: AppShellComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        redirectTo: 'pm',
        pathMatch: 'full',
      },
      {
        path: 'pm',
        loadChildren: () =>
          import('@nexuzhealth/moapr-hosp/patient/feature-shell').then((m) => m.MoaprHospPatientFeatureShellModule),
      },
      {
        path: 'resourceplanning',
        data: {
          breadcrumb: '_resourceplanning.navbar.title',
        },
        loadChildren: () =>
          import('@nexuzhealth/moapr-hosp/resourceplanning/feature').then(
            (mod) => mod.MoaprHospResourceplanningFeatureModule
          ),
      },
      {
        path: 'pharmacy',
        canActivate: [isAuthorizedGuard(AccessRole.clinical)],
        data: {
          breadcrumb: '_medication._pharmacy.navbar.title',
        },
        loadChildren: () =>
          import('@nexuzhealth/moapr-hosp/medication/pharmacy/shell').then((m) => m.pharmacyShellRoutes),
      },
      {
        path: 'settings',
        data: {
          breadcrumb: '_settings.navbar.title',
        },
        loadChildren: () =>
          import('@nexuzhealth/moapr-hosp/settings/feature-shell').then(
            (mod) => mod.MoaprHospSettingsFeatureShellModule
          ),
      },
      {
        path: 'messages',
        loadChildren: () =>
          import('@nexuzhealth/shared/communication/messages/feature-overview').then((m) => m.FeatureOverviewModule),
        canActivate: [
          isAuthorizedGuardFactory((featureFlagService: FeatureFlagService, featureFlagQuery: FeatureFlagQuery) =>
            featureFlagService.load('featureflags/nxh-mail').pipe(
              catchError(() => of(null)),
              mergeMap(() =>
                featureFlagQuery
                  .selectFeatureFlagEnabled('featureflags/nxh-mail')
                  .pipe(map((enabled) => (enabled ? undefined : AccessRole.clinical)))
              )
            )
          ),
        ],
      },
      {
        path: 'worklists',
        loadChildren: () => import('@nexuzhealth/shared/worklist/shell').then((m) => m.SharedWorklistShellModule),
      },
      {
        path: '',
        redirectTo: 'pm',
        pathMatch: 'full',
      },
      {
        path: '**',
        component: PageNotFoundComponent,
        data: {
          breadcrumb: '_page-not-found.breadcrumb',
        },
      },
    ],
    resolve: {
      references: ReferenceResolveService,
      theme: AuthenticatedThemeResolver,
      featureFlags: FeatureFlagResolver,
    },
    data: {
      flagNames: [
        'featureflags/select-french',
        'featureflags/care-rotation',
        'featureflags/worklist',
        'featureflags/nxh-mail',
        'featureflags/shared-inbox',
        'featureflags/ehealthbox-organisation',
        'featureflags/ehealthbox-send-via-organisation',
        'featureflags/mock-eid-supported',
      ],
    },
  },
];
