import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthQuery } from '@nexuzhealth/shared/authentication/data-access-auth';
import { SharedShellConfigService, ThemeService } from '@nexuzhealth/shared/toolkit/feature-shell';

import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { I18NextPipe } from 'angular-i18next';

@Component({
  selector: 'nxh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgbTooltipConfig],
})
export class AppComponent implements OnInit {
  constructor(
    private userContext: AuthQuery,
    private i18next: I18NextPipe,
    private router: Router,
    tooltipConfig: NgbTooltipConfig,
    private themeSettings: ThemeService,
    sharedShellConfig: SharedShellConfigService
  ) {
    sharedShellConfig.setup(tooltipConfig, { persistRecentPatients: true });
  }

  ngOnInit(): void {
    this.themeSettings.getThemeSettings();
    this.userContext.selectUser();
  }
}
